import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function InsuranceListComponent() {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#insurance') {
            let element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            } else {
                window.scrollTo({top: 0, left:0, behavior: "smooth"});
            }
        }
    }, [location]);

    const insurancesMap: any = {
        'AAR': 'aar.svg',
        'Britam': 'britam.png',
        'CIC': 'cic.webp',
        'Clarkson': 'clarkson.png',
        'GA Insurance': 'gainsurance.svg',
        'Jubilee': 'jubilee.png',
        'KCB': 'kcb.png',
        'Kenyan Alliance Insurance Company': 'kenyanallianceinsurancecompany.jpeg',
        'Liaison': 'liaison.png',
        'MUA Kenya': 'muakenya.jpeg',
        'Nyambene Arimi Sacco': 'nyambenearimisacco.jpeg',
        'Old Mutual': 'oldmutual.jpeg',
        'Postbank': 'postbank.png',
        'Sedgwick': 'sedgwick.jpg'
    };

	return (
    <div className="pt-2" id="insurance">
        <div className="row pt-5 text-center justify-content-center">
            <h1 className="mt-5 px-4 kaisei-font title-font-size">Insurance Plans</h1>
            <p className="fs-5 fw-light pb-3 pt-1 kaisei-font">Supporting the Help You Need</p>
            <p className="col-11 col-lg-9 text-center px-3 mt-4">
                Precious Life Healthcare is an appointed medical services provider for several major insurance companies,
                giving us the ability to provide care for most patients who walk through the door.
                If your plan isn&rsquo;t included in the accepted insurance list below, get in touch with us and we&rsquo;ll find an alternative way of taking care of you.
            </p>
        </div>
        <div className="row justify-content-center px-3 mx-auto my-3 py-5">
            {Object.keys(insurancesMap).sort().map((insurance, index) => {
                return (
                <div className="col-6 col-sm-4 col-lg-3 col-xl-2 d-flex justify-content-center align-items-center my-2 px-2 px-lg-3" key={index}>
                    <div className="row">
                        <img className="img-fluid img-thumbnail rounded" src={'/images/insurancethumbnails/' + insurancesMap[insurance]} key={index} alt={insurance}/>
                        <div><p className="fs-6">{insurance}</p></div>
                    </div>
                </div>
                )
            })}
        </div>
    </div>
	);
}

export default InsuranceListComponent;
