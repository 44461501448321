import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ContactComponent from "./ContactComponent";
import InsuranceListComponent from "./InsuranceListComponent";


import labservices_jpeg from './lab_services.jpeg';
import labservices_webp from './lab_services.webp';
import plhc_jpg from './plhc.jpg';
import plhc_webp from './plhc.webp';
import { GalleryCarousel } from './GalleryComponent';


function TitleList(props: any) {
  return (
    <div className="lab-services-cards-div col mx-md-2 my-3 kaisei-font">
        <div className="card mx-auto border-0">
            <div className='mb-2'>
                <h4 className='fw-medium'>{props.title}</h4>
            </div>
            <ul className="list-group list-group-flush py-0">
              {props.content_list.map((item: any, index: any) => {
                return <li key={index} className="list-group-item py-0 border-bottom">{item}</li>;
              })}
            </ul>
        </div>
    </div>
  );
}

function BusinessHours() {
  return (
    <div className="row mt-3 mb-md-3 mx-0 pt-5">
          {/* <!-- TODO: Switch font size to bootstrap representation--> */}
          <h1 className="mb-4 title-1-font-size kaisei-font">Business Hours</h1>
          {/* <!-- TODO: Yes cols?? --> */}
          <div className="w-100 mx-auto px-0">
              <div className="row w-90 mx-auto">
                  <div className="col-12 col-md-5 col-xxl-4 py-2 mx-auto fw-semibold fs-5 schedule-div">Monday - Friday: 8am - 6pm</div>
              </div>
          </div>
          <div className="w-100 mx-0 px-0 mt-3">
              <div className="row w-90 mx-auto">
                  <div className="col-12 col-md-5 col-xxl-4 py-2 mx-auto fw-semibold fs-5 schedule-div">Saturday: 8am - 5pm</div>
              </div>
          </div>
          <p className="pt-3 fs-6">* Closed on Sundays and all Kenyan public holidays</p>
      </div>
  );
}

function ServicesComponent() {
  const location = useLocation();
  const [showOutPatientServices, setShowOutPatientServices] = useState(true);

  useEffect(() => {
    if (location.hash === '#services') {
      let element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      } else {
        window.scrollTo({top: 0, left:0, behavior: "smooth"});
      }
    }
  }, [location]);

  const labServices: any = {
    "Biochemistry": ["Fasting Sugar", "Pregnancy", "Random Sugar"],
    "Parasitology": ["Malaria Blood Slide (BS)", "Stool Microscopy"],
    "Lipid Profile": ["HDL", "Total Cholestrol", "Triglycerides"],
    "Haematology": ["Blood Grouping", "ESR", "Full Haemogram", "Haemoglobin Level (HB)"],
    "Microbiology": [
      "Culture and Sensitivity", "Gram Stain", "H.V.S Wet Preparation",
      "Sputum for A.A.F.B 2 Sample", "Urethral Swap", "Urinalysis", "Urine Microscopy"],
    "Liver Function": ["ALT (SGPT)", "AST (SGOT)", "Direct Bilirubin", "Total Bilirubin"],
    "Renal Function": ["Calcium", "Chloride", "Creatinine", "Potassium", "Sodium", "Urea"],
    "Serology": [
      "ASOT", "Brucella", "GTT", "HBsAG", "HbA1C", "Helicobactor Pylori Ab",
      "Helicobactor Pylori Ag", "HIV Test",  "PSA", "TSH", "T4"]
  }

  const outpatientServices: any = {
    "Doctor's Consultation": "A meeting between you and a doctor. During the meeting, the doctor will asess your health and provide treatment or advice. Consultation can take place at our clinic or even over the phone.",
    "Clinical Officer's Consultation": "Similar to a doctor's consultation, this is a meeting with our professionals to discuss your health. The only difference is that this time you meet with a clinical officer.",
    "Hypertension Clinic": "This is our clinic that specializes with providing services to diagnose and treat conditions of high blood pressure. Services may include blook pressure monitoring, blood tests, and lifestyle counseling.",
    "Diabetics Clinics": "Our clinic to help with diabetes conditions. We provide blood sugar monitoring, medical evaluation, nutrition counseling and exercise counseling.",
    "Nutrition Counseling": "We provide advice on making healthy food choices to help you manage your weight, blood sugar, and blood pressure.",
    "Minor Surgical Procedures": "Minor surgical procedures are minimally invasive procedures that can be performed in an outpatient setting. They are typically performed under local anesthesia and can be used to treat a variety of conditions.",
    "Clinical Counseling": "Clinical counselling is a type of therapy that helps people deal with emotional, social, and psychological problems. It is provided by a licensed therapist or counselor who has specialized training in mental health. The goal of clinical counselling is to help people understand and cope with their problems in a healthy way."
  }

  return (
    <div id="services">
      <div className="row mx-0">
			<h1 className="mb-1 title-1-font-size kaisei-font">Our Services</h1>
			<p className="kaisei-font">Care by Professionals You Can Trust</p>
			<div className="row row-cols-1 row-cols-md-2 g-2 mt-0 mx-0 px-4 px-md-0 px-lg-2 px-xl-5">
				<div className="col pb-4 px-md-2 px-xl-4">
					<div className="card h-100">
            <picture>
              <source srcSet={plhc_webp} type="image/webp"/>
              <img src={plhc_jpg} className="card-img-top" alt="stethoscope"/>
            </picture>
						<div className="card-header fs-3 fw-bolder border-0 kaisei-font"
              style={{backgroundColor:"#D5896F"}}>
              Outpatient Services
            </div>
						<div className="card-body border-0"
              style={{backgroundColor:"#D5896F"}}>
							<p className="card-text"
                style={{backgroundColor:"#D5896F"}}>
								Through this service and our many others, Precious Life Healthcare provides the high quality, personalized care you deserve.
								With our experienced team and the best treatments available, you can rest assured that your health is in the best hands.
								Click below to view available services or contact us with any questions you may have.
							</p>
						</div>
						<div className="card-footer border-0 nav-item"
              style={{backgroundColor:"#D5896F"}}>
							<a id="view-outpatient-services-btn" href="#services-detail"
              className="btn border fw-bolder kaisei-font" onClick={() => setShowOutPatientServices(true)}>View Outpatient Services</a>
						</div>
					</div>
				</div>
				<div className="col pb-4 px-md-2 px-xl-4">
					<div className="card h-100">
						<picture>
							<source srcSet={labservices_webp} type="image/webp"/>
							<img src={labservices_jpeg} className="card-img-top" alt="lab technician working"/>
						</picture>
						<div className="card-header fs-3 fw-bolder border-0 kaisei-font"
              style={{backgroundColor:"#909BB4"}}>
              Laboratory Services
            </div>
						<div className="card-body border-0"
              style={{backgroundColor:"#909BB4"}}>
							<p className="card-text"
                style={{backgroundColor:"#909BB4"}}>
								Since Precious Life Healthcare opened, our patients have depended on us to provide this valuable service.
								As a pillar of the medical options we offer, physical examinations are comprehensive, fully personalized and administered
								by our team members who specialize in this type of care. To learn more or ask questions, simply reach out.
							</p>
						</div>
						<div className="card-footer border-0"
              style={{backgroundColor:"#909BB4"}}>
							<a id="view-available-lab-services-btn" href="#services-detail" className="btn border fw-bolder kaisei-font" onClick={() => setShowOutPatientServices(false)}>View Lab Services</a>
						</div>
					</div>
				</div>
			</div>
		</div>
    <div id="services-detail" className="row pt-xl-5 my-4 my-md-3 mx-auto">
      {/* <div className="mx-auto my-5">
        <a className="btn btn-lg btn-outline-primary kaisei-font"
        href="/appointments">Book an appointment and skip the queue!</a>
      </div> */}
        <div className="nav mx-auto p-2">
            <button className={"py-2 rounded-start w-50 border border-1 " + (showOutPatientServices ? "bg-outpatient-services text-white" : "bg-light")}
                onClick={() => { setShowOutPatientServices(true);}}>
                  Outpatient Services
            </button>
            <button className={"py-2 rounded-end w-50 border border-1 " + (showOutPatientServices ? "bg-light": "bg-lab-services text-white")} id="lab-services-tab"
				onClick={() => {setShowOutPatientServices(false);}}>Lab Services</button>
        </div>
        <div id="servicesTabContent" className='mx-auto my-3'>
            {showOutPatientServices && <div id="outpatient-services">                
                <div className="mx-auto">
                    {Object.keys(outpatientServices).map((key, index) => {
                        return <div className="mx-auto w-75 mb-5" key={index}>
                          <div className="card-body">
                            <h4 className="card-title my-2">{key}</h4>
                            <p className="card-text">{outpatientServices[key]}</p>
                          </div></div>
                      })}
                </div>
            </div>}
            {!showOutPatientServices && <div className="" id="lab-services" >
                <div className="row row-cols-auto row-cols-md-2 row-cols-lg-4
                  row-cols-xl-4 row-cols-xxl-4 justify-content-center">
                  {Object.keys(labServices).map((key, index) => {
                      return <TitleList key={index} title={key} content_list={labServices[key]}/> ;
                    })}
                </div>
            </div>}
        </div>
    </div>
    </div>
  );
}


const FacebookLink = () => {
  const facebookProfile = 'https://www.facebook.com/PLHCMAUA';

  return (
    <div className='my-3'>
      <img className='me-2'
        src="/images/fbicon.svg"
        alt="facebook"/>
        <a href={facebookProfile} target="_blank" rel="noopener noreferrer">
        Follow Us on Facebook
      </a>
    </div>
  );
};

function HomePage() {

	return (
	<div className="pt-2">
		<div className="row justify-content-center px-xl-5 pt-5 mx-0">
			<h1 className="mt-5 px-auto kaisei-font title-font-size">
				PRECIOUS LIFE HEALTHCARE
			</h1>
			<p className="fs-5 fw-light pb-5 pt-3 kaisei-font"> Restoring Health.</p>
			<div className="col-lg-10">
				<p className="fw-lighter pb-4 px-3 px-xl-5">
					Since 2000, we have been proud to serve in Maua.
					With our top-of-the-line services and technologies, we ensure that all patients receive the exceptional care they deserve.
					We&rsquo;re dedicated to not only making you feel better, but helping you stay healthy in the long run.
					Get in touch today to learn more.
				</p>
			</div>
		</div>
    <ServicesComponent/>
    <GalleryCarousel/>
    <InsuranceListComponent/>
    <BusinessHours/>
    <ContactComponent/>
    <FacebookLink/>
    <div className='mt-3 pt-5'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2757.9311411052986!2d37.93988077864274!3d0.23250734959601163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17883509bff74a0b%3A0x8d061fd7bde89e09!2sPrecious%20Life%20Health%20Services!5e0!3m2!1sen!2sus!4v1678557425942!5m2!1sen!2sus" title="business location" width="100%" height="400px" style={{border:0}} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
);
}

export default HomePage;
