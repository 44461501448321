import { useEffect } from "react";

import { useLocation } from "react-router-dom";

declare global {
    interface Window {
        grecaptcha: any;
    }
}


function ContactComponent() {
    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#contact') {
            let element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            } else {
                window.scrollTo({top: 0, left:0, behavior: "smooth"});
            }
        }
    }, [location]);

    let sleep = (milliseconds: any) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async function submitContactForm(e: any) {
        e.preventDefault();
        e.target.submitbtn.disabled = true;

        let elements = e.target.elements;
        let data = {
            'name': elements['name'].value,
            'email': elements['email'].value,
            'message': elements['message'].value,
            'g-recaptcha-response': ''
        }
        window.grecaptcha.ready(function () {
            let key: any = process.env.REACT_APP_RECAPTCHA_SITEKEY;
            window.grecaptcha.execute(
                key,
                {'action': "contact_email_form"}
            ).then((token: any) => {
                data['g-recaptcha-response'] = token;
                fetch(
                    '/api/send_contact_email',
                    {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify(data)
                    }
                ).then(x => {
                    if (x.status === 400) {
                        alert('Check That The Form Is Filled Completely.');
                    } else if (x.status === 500) {
                        alert('Error Occurred. Try Again.');
                    } else {
                        e.target.reset();
                        alert('Message Sent Successfully');
                    }
                    e.target.submitbtn.disabled = false;
                }).catch(x => {
                    alert('Error Occurred. Try Again.');
                    e.target.submitbtn.disabled = false;
                });
            })
        });
    }

    return (
    <div className="mb-4 mt-5 my-md-0 mx-2 pt-md-0 px-xl-5" id="contact">
        <h1 className="pt-3 pb-3 title-1-font-size kaisei-font">Contact Us</h1>
        <p className="pb-3 mx-md-5 px-xl-5">
            Precious Life Healthcare is committed to providing quality and comprehensive healthcare.
            To learn more about our clinic and how we can help you, get in touch with us today by
            filling out the form below, calling or emailing us.</p>
        <p className="pb-1 fw-bold kaisei-font" id="contact-prompt">Leave us a message below. We will get back to you.</p>
        <form id="contact-form" className="mt-3 col-xxl-9 mx-xxl-auto" data-toggle="validator" onSubmit={(e) => {submitContactForm(e)}}>
            <div className="controls justify-content-center">
                <div className="row mx-auto justify-content-center">
                    <div className="px-0 col-md-9 col-lg-7 col-xl-4">
                        <div className="form-group px-1">
                            <label htmlFor="form_name">Name *</label>
                            <input id="form_name" type="text" name="name" className="form-control" placeholder="Please enter your name"
                            aria-required="true" data-error="Name is required."/>
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
                    <div className="px-0 col-md-9 col-lg-7 col-xl-4">
                        <div className="form-group px-1">
                            <label htmlFor="form_email">Email *</label>
                            <input id="form_email" type="email" name="email" className="form-control" placeholder="Please enter your email"
                            aria-required="true" data-error="Valid email is required."/>
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
                </div>
                <div className="row mx-auto justify-content-center mt-2">
                    <div className="px-0 col-md-9 col-lg-7 col-xl-8">
                        <div className="form-group">
                            <label htmlFor="form_message" className="mb-1">Message *</label>
                            <textarea id="form_message" name="message" className="form-control" placeholder="Message for us..."
                            aria-required="true" data-error="Please, leave us a message."></textarea>
                            <div className="help-block with-errors"></div>
                        </div>
                    </div>
                    <div id="contact_form_footer" className="col-md-12">
                        <input className="btn send-button submit-btn mt-3" type="submit" value="Send message" name="submitbtn"/>
                    </div>
                </div>
                <div className="row py-1">
                    <div className="col-md-12">
                        <p className="text-muted">* Required Fields</p>
                    </div>
                </div>
            </div>
        </form>
        <div className="ps-2 pt-3 pt-xl-4 mb-md-2">
            <h3 className="text-start text-md-center pb-0 mb-1">PLHC</h3>
            <p className="text-start text-md-center pb-0 mb-1">0716418886</p>
            <p className="text-start text-md-center pb-0 mb-0">P.O Box 600</p>
            <p className="text-start text-md-center pb-0 mb-0">Maua, Kenya</p>
            <p className="text-start text-md-center pb-0 mb-0">60600</p>
        </div>
    </div>
    );
}

export default ContactComponent;
