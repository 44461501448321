import { useState } from 'react';
import { Link, NavLink, Route, Routes } from 'react-router-dom';

import './styles/App.css';
import './styles/base.css';
import './styles/home.css';
import './styles/custom_fonts.css';

import HomePage from './components/HomePage';


function BaseDisplay() {
	const [menuShown, setMenuShown] = useState(false);

	const menuItems: {[key: string]: string} = {
		'Services': '/#services',
		'Gallery' : '/#gallery',
		'Insurance' : '/#insurance'
	};

	return (
	<div className='mx-auto'>
		<nav className="navbar navbar-expand-lg navbar-light py-0 bg-light fixed-top">
			<div className="container-fluid px-0">
				<div className='navbar-brand mx-2 mb-3 mb-lg-0 mt-2 p-0'>
					<Link to="/" className='nav-link active ms-lg-2'>
						<div className='p-0 m-0'>
							<p className='navbar-brand-div py-0 px-2 m-0 text-uppercase'>PLHC</p>
						</div>
					</Link>
				</div>
				<button className="navbar-toggler mx-2" type="button" onClick={()=>{menuShown?setMenuShown(false):setMenuShown(true);}}>
					<span className="navbar-toggler-icon "></span>
				</button>
				<div className={"collapse navbar-collapse justify-content-center mb-2 bg-light " + (menuShown? "show": "")}>
					<ul className="navbar-nav mb-2 mb-lg-0 me-auto mx-lg-auto mx-1 ps-2 ps-lg-auto bg-light">
						{Object.keys(menuItems).map((item, index) => {
						return (<li className="nav-item  mx-lg-3 bg-light" key={index}>
							<NavLink to={menuItems[item]} className='nav-link active fs-6 text-start text-lg-center  text-uppercase bg-light' onClick={()=> {setMenuShown(false);}}>{item}</NavLink>
						</li>)
						})}
					</ul>
				</div>
			</div>
		</nav>
		<div className='container pb-5 mt-5 pt-2'>
			<Routes>
				<Route path="/" element={<HomePage/>}/>
			</Routes>
		</div>
		<div className='row'>
			<p className='fs-6'>Copyright SavannaDevs</p>
		</div>
	</div>
	);
}

function App() {
	return (
	<div className="App">
		<BaseDisplay/>
	</div>
	);
}

export default App;
