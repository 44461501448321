import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function GalleryCarousel() {
    const galleryImages = [
        ['2', 'Our laboratory equipment: Microscope'],
        ['4', 'Our laboratory equipment'],
        ['3', 'Our laboratory equipment: Centrifuge'],
        ['5', 'Optician Services'],
        ['6', 'Doctor 2 Offices'],
    ];

    const location = useLocation();
    useEffect(() => {
        if (location.hash === '#gallery') {
            let element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            } else {
                window.scrollTo({top: 0, left:0, behavior: "smooth"});
            }
        }
    }, [location]);

    return (
        <div className="row mx-auto" id="gallery">
            <h1 className="mt-5 px-4 kaisei-font title-font-size">Our Clinic Gallery</h1>
            <div id="clinicGallery" className="carousel slide mx-auto mt-4 col-12 col-md-8 col-xl-6">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="/images/plhcimage1.jpeg" className="img-fluid" alt="plhc iamge1"/>
                        <div className="carousel-caption d-none d-md-block">
                            <p className="text-dark">Dr Kanake</p>
                        </div>
                    </div>
                    {galleryImages.map((image, index) => (
                    <div className="carousel-item" key={index}>
                        <img key={index} src={`/images/plhcimage${image[0]}.jpeg`}  className="img-fluid" alt={image[1]}/>
                        <div className="carousel-caption d-none d-md-block">
                            <p className="text-dark">{image[1]}</p>
                        </div>
                    </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#clinicGallery" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#clinicGallery" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};
